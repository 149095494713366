import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useToken from '../hooks/useToken';
import moment from 'moment-jalaali'
import DatePicker from 'react-multi-date-picker';
import * as XLSX from 'xlsx'
import { ClipLoader, HashLoader } from 'react-spinners';
import {DropDown, CheckBox, HDatePicker } from '../components';
import to from 'await-to-js';
import persian_fa from "react-date-object/locales/persian_fa";
import persian from "react-date-object/calendars/persian";
import { MdDelete } from "react-icons/md";
import { FaPlus, FaEdit } from "react-icons/fa";

const UploadMessages = ({title, addToastHandler}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();

  const [messageFields, setMessageFields] = useState([
    {dbField: 'resaneh', title:'رسانه', excelColumn: 'relation.title', required: false},
    {dbField: 'link', title:'لینک', excelColumn: 'url', required: false},
    {dbField: 'titr', title:'تیتر', excelColumn: 'title', required: false}])

  const [accessToken] = useToken();
  const [file, setFile] = useState(null);
  const [changeResanehTitles, setChangeResanehTitles] = useState([{resaneh: 'درگاه اصلی آستان قدس رضوی', newResaneh: 'آستان قدس رضوی'},{resaneh: 'خراسان خبر', newResaneh: 'خراسان'},{resaneh: 'آستان نیوز - پایگاه اطلاع رسانی آستان قدس رضوی', newResaneh: 'آستان نیوز'}]);
  const [messages, setMessages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject , setSelectedSubject ] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform , setSelectedPlatform ] = useState(null);
  const [messagesDate, setMessagesDate] = useState(moment().format('YYYY-MM-DD'));
  const [msg, setMsg] = useState({type: 'success', message:'خوش آمدید...'})
  const [dataakToken, setDataakToken] = useState("");
  const [showAddSubjectPanel, setShowAddSubjectPanel] = useState(null);
  const [newSubject, setNewSubject] = useState({title: '', description: '', regionFrom: null, regionTo: null});
  const [refrenceType, setRefrenceType] = useState('dataak');
  const [hozehList, setHozehList] = useState([]);

  const abortRef = React.useRef(false);

  const selectFileRef = useRef(null);

  useEffect(() => {
    getAppConfig()
    getSubjects()
    getPlatforms()
    getHozehList()
  }, [])

  const getHozehList = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      return
    }
    setHozehList([... new Set(response.data.results.map(h => h.hozeh))].map(h => {return {id: h, title: h}}))
  }

  const getAppConfig = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/config/get-all-config`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }
      setDataakToken(response.data.results.filter(c => c.title === 'dataak')[0].config.token)
  }

  const getSubjects = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/subject/subject/get-subjects`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setSubjects(response.data.results)
  }

  const getPlatforms = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/platform/platform/get-platforms`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setPlatforms(response.data.results)
  }

  const handleReadExcel = async (f) => {
    if(!f){
      // setMsg('فایلی انتخاب نشده است.')
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'فایلی انتخاب نشده است.',
        type: 'error', 
        seconds: 3
      })
      return
    }

    if (f) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const records = XLSX.utils.sheet_to_json(worksheet);

        setMessages(records.map(m=>{return {...m, ['relation.title']: changeResanehTitles.filter((r) => r.resaneh===m['relation.title']).length>0?changeResanehTitles.filter((r) => r.resaneh===m['relation.title'])[0].newResaneh:m['relation.title']}}))
        const lns = records.map((j,_)=>Object.entries(j).length)
        const indexOfMaxLength = lns.indexOf(Math.max(...lns))
        // setColumnCountIdx(indexOfMaxLength)
        const clmns = Object.keys(records[indexOfMaxLength]).map((k,idx)=>{return {id: idx, key: k}})
        setColumns(clmns)

        setMessageFields(messageFields.map(om=>
        {
          let ttt = []
          let excelColumn = ''
          switch (om.dbField) {
            case 'resaneh':
              ttt = ['relation.title']
              break;
            case 'titr':
              ttt = ['title']
              break;
            case 'link':
              ttt = ['url']
              break;
          }
          for (let i = 0; i < ttt.length; i++) {
            if(clmns.filter(c=>c.key === ttt[i]).length > 0){
              excelColumn = ttt[i]
              break
            }
          }
          return {...om, excelColumn: excelColumn}
        }
        ))
        setProgress(0)
      };
      reader.readAsBinaryString(f);
    }
  };

  const handleUpload = ()=>{
    handleInsertMessages()
  }

  const handleInsertMessages = async () => {
    if(messageFields.filter((mf) => mf.excelColumn === '').length > 0){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'فیلدها را مشخص کنید.',
        type: 'error', 
        seconds: 3
      })
      return;
    }
    setIsLoading(true)
    const messagesR = messages.map((m) => {return {...m, resaneh: m.resaneh}})
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/insert-web-messages`,
        {
          messages: messagesR.map(m=>{return Object.fromEntries([['postDate', m.date?moment(m.date, 'jYYYY/jMM/jDD').format('YYYY-MM-DD'):messagesDate],...Object.keys(m).filter(((mk) => messageFields.map((f) => f.excelColumn).includes(mk))).filter(mk=>mk!=='upload').map((mk)=>[messageFields.filter(mf=>mf.excelColumn === mk)[0].dbField,m[mk].toString().trim()])])})
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      setMsg({type: 'error', message: 'در هنگام بارگذاری خطایی رخ داده است...'})
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setMessages([])
    setFile(null)
    setMsg({type: 'success', message:'اطلاعات با موفقیت بارگذاری شد...'})
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'اطلاعات با موفقیت بارگذاری شد.',
      type: 'success', 
      seconds: 3
    })
  }

  const handleSearch = async(resource, tag, subject)=>{
    let params = 'https://api.dataak.com/search/api/v1/search?api_path=%2Fnews%2Fposts&want_tag_content=1'
    if(resource !== null){
      params += '&tag_id=' + resource
    }
    if(tag !== null){
      params += '&content_tag_ids%5B0%5D=' + tag
    }
    if(subject !== null){
      params += '&query=' + subject
    }
    const [err, response] = await to(
      axios.get(
        `${params}&from=${(new Date(moment(messagesDate).format('YYYY-MM-DD 00:00:00'))).getTime()/1000}&to=${(new Date(moment(messagesDate).format('YYYY-MM-DD 23:59:59'))).getTime()/1000}&count=100&sort=time&sort_type=desc&not_resource_ids%5B1%5D=razavi.ir&smart_highlight=1`,
        {
          headers: { Authorization: `Bearer ${dataakToken}` }
        }
      )
    )

    if(err){
      throw new Error('error in database.')
    }
    return response.data.result.data
  }

  const handleDirectUpload = async() => {
    setIsLoading(true)
    let records = []
    const [err1, data1] = await to(handleSearch(9164, 3547, 21840))

    if(err1){
      throw new HttpException('error in database.', HttpStatus.INTERNAL_SERVER_ERROR)
    }
    records =[...data1]

    const [err2, data2] = await to(handleSearch(9220, 3547, null))

    if(err2){
      throw new HttpException('error in database.', HttpStatus.INTERNAL_SERVER_ERROR)
    }
    records =[...records, ...data2]
    const msgs = records.map(m=>{return {...m, ['relation.title']: changeResanehTitles.filter((r) => r.resaneh===m['relation.title']).length>0?changeResanehTitles.filter((r) => r.resaneh===m['relation.title'])[0].newResaneh:m['relation.title']}}) 
    setMessages(msgs)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/insert-web-messages`,
        {
          messages: msgs.map(m=>{return {postDate: messagesDate, titr: m.title, link: m.url, resaneh: m['relation.title']}})
          // messages: msgs.map(m=>{return Object.fromEntries([['postDate', m.time?moment(m.time, 'jYYYY/jMM/jDD').format('YYYY-MM-DD'):messagesDate],...Object.keys(m).filter(((mk) => messageFields.map((f) => f.excelColumn).includes(mk))).filter(mk=>mk!=='upload').map((mk)=>[messageFields.filter(mf=>mf.excelColumn === mk)[0].dbField,m[mk].toString().trim()])])})
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      setMsg({type: 'error', message: 'در هنگام بارگذاری خطایی رخ داده است...'})
      setIsLoading(false)
      return
    }
    setMsg({type: 'success', message:'اطلاعات با موفقیت بارگذاری شد...'})
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'اطلاعات با موفقیت بارگذاری شد.',
      type: 'success', 
      seconds: 3
    })
    setIsLoading(false)
  }

  const handleUploadSubjectedMessages = async() => {
    if(selectedSubject === null) return
    setIsLoading(true)
    const body = {
      subjectId: selectedSubject,
      messages: messages.map(m => {return {
        text: (m.title || '') + '\n' + m.description,
        postDate: refrenceType==="dataak"?m['time-date']+" "+m['time-time']:m.time,
        originMessageId: m.id,
        platformId: selectedPlatform,
        subjectId: selectedSubject,
        url: m.url,
        viewCount: m.view_count || 0,
        username: selectedPlatform === 7?m['relation.title']:(selectedPlatform === 8?m.resource_id:m.username)
    }})}

    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/insert-messages`,body,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err.message)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      setMsg({type: 'error', message: 'در هنگام بارگذاری خطایی رخ داده است...'})
      return
    }

    setMsg({type: 'success', message:'اطلاعات با موفقیت بارگذاری شد...'})
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'اطلاعات با موفقیت بارگذاری شد.',
      type: 'success', 
      seconds: 3
    })
    setIsLoading(false)
  }
  
  const addSubjectHandler = async() => {
    if(newSubject.title.trim().length === 0) return
    // if(showAddSubjectPanel > -1 && subjects.filter(s => s.title === newSubjectTitle.trim()).length > 0) return
    setIsLoading(true)
    const body = {
      title: newSubject.title.trim(),
      description: newSubject.description,
      regionFrom: newSubject.regionFrom,
      regionTo: newSubject.regionTo,
    }

    let url = process.env.REACT_APP_BASE_URL
    if(showAddSubjectPanel === -1){
      url += '/subject/subject/insert-subject';
      const [err, response] = await to(
        axios.post(
          `${url}`,body,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      )
  
      if(err){
        console.log(err)
        addToastHandler({
          id: Math.floor(Math.random()*3000),
          title: 'خطا',
          description: err.message,
          type: 'error', 
          seconds: 3
        })
        return
      }
    }

    if(showAddSubjectPanel > -1){
      url += `/subject/subject/update-subject/${showAddSubjectPanel}`;
      const [err, response] = await to(
        axios.put(
          `${url}`,body,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      )
  
      if(err){
        console.log(err)
        addToastHandler({
          id: Math.floor(Math.random()*3000),
          title: 'خطا',
          description: err.message,
          type: 'error', 
          seconds: 3
        })
        return
      }
    }

    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'سوژه با موفقیت ثبت شد.',
      type: 'success', 
      seconds: 3
    })
    getSubjects()
    setShowAddSubjectPanel(null)
    setNewSubject({title: '', description: '', regionFrom: null, regionTo: null})
    setIsLoading(false)
  }

  moment.loadPersian()
  return (
    <div className="relative h-full box-border flex flex-col gap-1 justify-start items-stretch">
      {/* header */}
      <div className="flex justify-start items-center gap-2">
        <DatePicker
          value={moment(messagesDate).format('jYYYY-jMM-jDD HH:mm:ss')}
          calendar={persian}
          locale={persian_fa}
          showOtherDays={true}
          zIndex={500000}
          format="YYYY-MM-DD"
          onChange={(d)=>{
            setMessagesDate(moment(d.year + '-' + d.month + '-' + d.day ,'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD'))
          }}
          inputClass='w-[calc(100%-0.5rem)] border dark:border-gray-500 rounded-md z-50 bg-transparent mx-1 px-2 py-1 text-center text-sm font-bold dark:text-gray-300'
        />
        <button type="button" onClick={handleDirectUpload} className="w-full max-w-xs text-xs text-white font-bold rounded px-2 py-1 bg-green-400 hover:bg-green-500 dark:bg-teal-600 dark:hover:bg-teal-400 whitespace-pre">دریافت مستقیم با تاریخ و بارگذاری</button>
      </div>
      <p className="text-xs py-1 text-red-500 whitespace-pre">{messages[0]?.date?`(فایل شامل فیلد date-نمونه ${messages[0]?.date})`:''}</p>
      <div className="flex w-full justify-start items-center gap-2 p-1">
        <div className="flex justify-start items-center gap-2">
          <button type="button" className="px-2 py-1 bg-blue-400 hover:bg-blue-300 rounded whitespace-pre" onClick={() => selectFileRef.current.click()}>انتخاب فایل</button>
          <input onChange={(e) => { setFile(e.target.files[0]); handleReadExcel(e.target.files[0]); }} onClick={(e) => { e.target.value = null; }} accept="/excel/*.xlsx" ref={selectFileRef} type="file" className="hidden"/>
          <p className="text-xs py-1">{file ? file.name : 'فایلی انتخاب نشده است.'}</p>
        </div>
        <button type="button" onClick={handleUpload} className="w-full max-w-xs text-xs text-white font-bold rounded px-2 py-1 bg-green-400 hover:bg-green-500 dark:bg-teal-600 dark:hover:bg-teal-400">بارگذاری</button>
        {/* {!isLoading && <button onClick={handleUpload} className='rounded px-2 py-1 bg-green-400 hover:bg-green-500 dark:bg-teal-600 dark:hover:bg-teal-400'>آپلود</button>} */}
      </div>
      {isLoading && <p className="rounded px-2 py-1 text-teal-600 text-xs whitespace-pre">در حال ارسال اطلاعات... {messages.length} / {progress}</p>}
      {isLoading && <button type="button" className="underline hover:font-bold" onClick={() => { abortRef.current = true; }}>انصراف</button>}
      {messages.length > 0 && <p className="text-xs font-bold text-gray-800 dark:text-gray-400 whitespace-pre">{`تعداد پیام: ${messages.length}`}</p>}
      {/* <hr/>
      <div className="grid grid-cols-6 md:grid-cols-12 gap-1 text-xs">
        {messageFields.map((f, idx) => (
          <div key={idx} className={`col-span-2 flex justify-center items-center gap-2`}>
            <p className={`text-md ${f.required && 'text-red-600 font-bold'}`}>{f.required && ' * '}{f.title}</p>
            <DropDown className="flex-grow" value={f.excelColumn} onChange={(s) => setMessageFields((o) => o.map((om) => { if (om.dbField === f.dbField) { return { ...om, excelColumn: s }; } return om; }))} data={columns.map((c) => { return {value: c.key, title: c.key}})} />
          </div>
        ))}
        <button type="button" onClick={handleUpload} className="col-span-6 md:col-span-1 w-full md:w-32 text-xs text-white font-bold rounded px-2 md:ms-20 py-1 bg-green-400 hover:bg-green-500 dark:bg-teal-600 dark:hover:bg-teal-400">بارگذاری</button>
      </div> */}
      <hr/>
      <div className="grid grid-cols-8 md:grid-cols-12 gap-1 text-xs">
        <div className="col-span-8 md:col-span-4 flex flex-wrap justify-start items-center gap-2">
          <p className={`text-md`}>سوژه</p>
          <DropDown className="flex-grow" value={selectedSubject} onChange={(s) => setSelectedSubject(s)} data={subjects} fields={{value: 'id', title: 'title'}} />
          {selectedSubject != null && <button onClick={() => {setShowAddSubjectPanel(selectedSubject); setNewSubject(subjects.filter(s => s.id === selectedSubject)[0])}} className="rounded border border-blue-300 dark:border-teal-500 p-1 text-blue-300 hover:text-blue-500 dark:text-teal-300 dark:hover:text-teal-500"><FaEdit/></button>}
          <button onClick={() => {setShowAddSubjectPanel(-1); setNewSubject({title: '', description: '', regionFrom: null, regionTo: null})}} className="rounded border border-blue-300 dark:border-teal-500 p-1 text-blue-300 hover:text-blue-500 dark:text-teal-300 dark:hover:text-teal-500"><FaPlus/></button>
        </div>
        <div className="col-span-8 md:col-span-4 flex flex-wrap justify-start items-center gap-2">
          <p className={`text-md`}>پلتفورم</p>
          <DropDown className="flex-grow" value={selectedPlatform} onChange={(s) => setSelectedPlatform(s)} data={platforms} fields={{value: 'id', title: 'title'}} />
          <CheckBox title="دیتاک" value={refrenceType==="dataak"} onChange={(e) => setRefrenceType('dataak')} />
          <CheckBox title="لایف‌وب" value={refrenceType==="lifeweb"} onChange={(e) => setRefrenceType('lifeweb')} />
          <button type="button" onClick={handleUploadSubjectedMessages} className="text-xs text-white font-bold rounded px-2 py-1 bg-green-400 hover:bg-green-500 dark:bg-teal-600 dark:hover:bg-teal-400">بارگذاری پیام‌های سوژه</button>
        </div>
      </div>
      {showAddSubjectPanel !== null && <div className="absolute w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="w-1/2 h-97 flex flex-col justify-center items-center gap-2 rounded-lg bg-white dark:bg-slate-700 p-2">
          <input type="text" className="w-full dark:bg-gray-800 rounded px-2 py-1 outline-none" value={newSubject.title} onChange={(e) => setNewSubject({...newSubject, title: e.target.value})} />
          <DropDown data={hozehList} fields={{value: 'title', title: 'title'}} className="w-full" value={newSubject.description} onChange={(s) =>{setNewSubject({...newSubject, description: s})}} />
          <div className="flex justify-between items-center">
          <HDatePicker className={`text-sm`} date={[newSubject.regionFrom, newSubject.regionTo]} setDate={(d) => {setNewSubject({...newSubject, regionFrom: d[0], regionTo: d[1]})}} />

          </div>
          <div className="flex justify-center items-center gap-2">
            <button onClick={()=> {setShowAddSubjectPanel(null)}} className="rounded border border-red-300 dark:border-amber-500 p-1 text-red-300 hover:text-red-500 dark:text-amber-300 dark:hover:text-amber-500">انصراف</button>
            <button onClick={()=> {addSubjectHandler()}} className="rounded border border-blue-300 dark:border-teal-500 p-1 text-blue-300 hover:text-blue-500 dark:text-teal-300 dark:hover:text-teal-500">ثبت</button>
          </div>
        </div>
      </div>}
      <div className="h-[5px] bg-blue-100 rounded flex justify-start items-center">
        <div style={{ width: `${messages.filter((r) => r.upload === 'ok').length / messages.length}%` }} className="h-full bg-blue-500 rounded flex justify-start items-center" />
        <div style={{ width: `${messages.filter((r) => r.upload === 'error').length / messages.length}%` }} className="h-full bg-yellow-500 rounded flex justify-start items-center" />
        <div style={{ width: `${messages.filter((r) => r.upload === 'duplicate').length / messages.length}%` }} className="h-full bg-red-500 rounded flex justify-start items-center" />
      </div>
      {messages.length === 0 && <p className={`w-full text-xs text-center font-bold ${msg.type === 'error'?'text-red-600':'text-green-600'}`}>{msg.message}</p>}
      <div className="grid grid-cols-12 justify-between items-center gap-2">
        {messages.length > 0 && Object.keys(messages[0]).filter(((mk) => messageFields.map((f) => f.excelColumn).includes(mk))).map((ck, idx)=>
        <p key={idx} className="col-span-4 text-center text-sm font-bold">{messageFields.filter(mf=>mf.excelColumn === ck).length > 0?messageFields.filter(mf=>mf.excelColumn === ck)[0].title:''}</p>
        )}
      </div>
      <div className="flex-grow overflow-y-auto">
        {messages.map((m,idx)=>
        <div key={idx} onMouseEnter={() => {setHoverIndex(idx)}} className={`grid grid-cols-10 justify-between items-center gap-2 rounded-md ${hoverIndex === idx && 'border bg-gray-100 dark:bg-gray-500'}`}>
          {Object.keys(m).filter(((mk) => messageFields.map((f) => f.excelColumn).includes(mk))).map((mk,index)=>
          <p key={index} className="col-span-3 truncate text-sm">{m[mk]}</p>
          )}
          <button onClick={() => {setMessages(messages.filter((mm, index) => index !== idx))}} className="text-red-600 hover:text-red-400"><MdDelete /></button>
        </div>
        )}
      </div>
    </div>
  );
};

export default UploadMessages;
